import React from 'react'
import Link from 'gatsby-link'
import { GatsbyImage } from 'gatsby-plugin-image'
import { MDBAnimation, MDBRow, MDBCol } from 'mdbreact'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faUpRightFromSquare, faCalendar } from '@fortawesome/pro-solid-svg-icons'
import moment from 'moment'

class CardNews extends React.Component {
  render() {
    const item = this.props.item
    const itemImage = item.image && item.image.localFile ? item.image.localFile.childImageSharp.gatsbyImageData : this.props.newspaper.childImageSharp.gatsbyImageData


    return (
      <MDBCol lg="4" md="6" >
        <MDBAnimation reveal type="fadeInUp" duration="1587ms">
          <MDBRow>
            <MDBCol lg="12" md="12" className="d-flex">
              <div className="card mb-4 flex-fill">
                <div className="view-news overlay rounded-top drop-shadow" style={{ maxHeight: '300px', }} >
                  {item.slug.substring(0, 4) != 'http' ?
                    <Link to={`/news/` + item.slug + `/`}> <GatsbyImage image={itemImage} className="img-fluid" alt={item.title} /></Link>
                    :
                    <a href={item.slug} target="_blank"> <GatsbyImage image={itemImage} className="img-fluid" alt={item.title} /></a>
                  }
                </div>
                {item.slug.substring(0, 4) != 'http' ?
                  <Link to={`/news/` + item.slug + `/`} className="btn-floating btn-action ml-auto mr-4 bg-ug-yellow"><FontAwesomeIcon icon={faChevronRight} className="pl-1" /></Link>
                  :
                  <a href={item.slug} target="_blank" className="btn-floating btn-action ml-auto mr-4 bg-ug-yellow"><FontAwesomeIcon icon={faUpRightFromSquare} className="pl-1" /></a>
                }
                <div className="card-body card-body-news" style={{ minHeight: '180px', maxHeight: '180px', }} >
                  {item.slug.substring(0, 4) != 'http' ?
                    <Link to={`/news/` + item.slug + `/`} style={{ color: '#000' }}><p className="font-w-400 text-xs-medium text-medium pt-3">{item.title}</p></Link>
                  :
                    <a href={item.slug} style={{ color: '#000' }}><p className="font-w-400 text-xs-medium text-medium pt-3">{item.subtitle}: {item.title}</p></a>
                  }
                </div>
                <div className="rounded-bottom bg-ug-yellow text-center pt-3 bg-card-btm">
                  <ul className="list-unstyled list-inline font-w-400 letter-spacing-2 text-small news">
                    <li className="list-inline-item pr-2 white-text"> <FontAwesomeIcon icon={faCalendar} className="mr-2" /> {moment(item.date).format('YYYY/MM/DD')} </li>
                  </ul>
                </div>
              </div>
            </MDBCol>
          </MDBRow>
        </MDBAnimation>
      </MDBCol>
    )
  }
}
  
export default CardNews